<template>
    <div class="me-container">
        <div class="header">
            <div class="name-wrapper">
                <div class="left">
                    <img :src="ME_ACTIVE_SRC" alt="" />
                </div>
                <div class="right">welcome, {{getName()}}</div>
            </div>
        </div>
        <div class="center-btn">
            <div class="center">
                <div class="number">{{typeof user.total_card === 'number' ? user.total_card : '--'}}</div>
                <div class="info">LOYALTY</div>
                <div class="info">PASSES</div>
            </div>
            <!-- <div class="right">
                <div class="number">{{typeof user.total_point === 'number' ? user.total_point : '--' }}</div>
                <div class="info">TOTAL</div>
                <div class="info">POINTS</div>
            </div> -->
        </div>
        <div class="link-wrapper">
            <div class="name-wrapper" @click.prevent="jumpTo">
                <div class="left">
                    <img :src="ACCOUNT_SET_SRC" alt="" />
                </div>
                <div class="right">Account Setting</div>
            </div>
            <div class="name-wrapper" @click.prevent="gotoSignout">
                <div class="left">
                    <img :src="OUT_SRC" alt="" />
                </div>
                <div class="right">Sign out</div>
            </div>
        </div>
        <vModal v-if="isReadyOut" type="box" :disableMaskClick="true">
            <div slot="header" class="modal-header">
                Are you sure you want to sign out right now?
            </div>
            <div slot="footer" class="modal-overlay-footer">
                <div class="footer-btn">
                    <div class="cancel" @click.prevent="cancel">Later</div>
                    <div @click.prevent="signOut">{{is_loading ? 'Signing out...' : 'Sign out'}}</div>
                </div>
            </div>
        </vModal>
    </div>
</template>
<style scoped>
    /* 顶部样式 */
    .me-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #FAF7F7;
    }

    .header {
        width: 375px;
        height: 160px;
        background-color: #F2F2F2;
        color: #000;
        opacity: .8;
    }

    .name-wrapper {
        display: flex;
        padding: 40px 36px;
        align-items: center;
    }

    .name-wrapper .left {
        background-color: #F2F2F2;
        font-size: 16px;
        color: #000;
    }

    .name-wrapper .left img {
        width: 38px;
        height: 38px;
    }

    .name-wrapper .right {
        font-size: 14px;
        line-height: 17px;
        max-width: 200px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-left: 18px;
    }

    /* 中间按钮样式 */
    .center-btn {
        position: absolute;
        width: 313px;
        height: 100px;
        border-radius: 12px;
        font-size: 12px;
        margin: -65px 17px 17px 17px;
        padding: 14px;
        text-align: center;
        overflow: hidden;
        background-color: #08bcaa;
        color: #CFFFF9;
        display: flex;
    }

    .center-btn .center {
        width: 100%;
    }

    .center-btn .left {
        width: 50%;
        border-right: 1px solid #fff;
    }

    .center-btn .right {
        width: 50%;
    }

    .center-btn .number {
        font-size: 48px;
        font-weight: bolder;
    }

    /* 链接样式 */
    .link-wrapper {
        margin: 80px 0;
    }

    .link-wrapper .name-wrapper .left {
        background-color: #FAF7F7;
    }

    .link-wrapper .name-wrapper img {
        width: 29.6px;
        height: 29.6px;
    }

    .link-wrapper .name-wrapper {
        padding: 10px 36px;
    }

    /* Modal样式 */
    .modal-header {
        line-height: 25px;
        padding: 25px;
    }

    .modal-overlay-footer .footer-btn {
        display: flex;
        align-items: baseline;
        text-align: center;
        font-size: 16px;
        color: #08bcaa;
        justify-content: space-around;
    }

    .modal-overlay-footer .footer-btn div.cancel {
        color: #000;
        opacity: 0.8;
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { ME_ACTIVE_SRC, ACCOUNT_SET_SRC, OUT_SRC } from '../../consts/const.js';
    import { getUserName } from '../../kits/tools.js';
    import vModal from '../../components/Modal.vue';

    export default {
        name: 'v-me',
        components: { vModal },
        data() {
            return {
                ME_ACTIVE_SRC, ACCOUNT_SET_SRC, OUT_SRC,
                isReadyOut: false
            }
        },
        created() {

        },
        mounted() {
            this.initLoyaltyUserInfo({ data: { id: this.user.id } });
        },
        computed: {
            ...mapGetters({
                is_loading: 'app/getLoading',
                user: 'app/getUser',
            })
        },
        methods: {
            ...mapActions({
                initLoyaltyUserInfo: 'app/initLoyaltyUserInfo',
            }),
            getName() {
                return getUserName(this.user);
            },
            jumpTo() {
                this.$router.replace('/profile/view');
            },
            signOut() {
                const system = JSON.parse(window.localStorage.getItem('system') || '{}');
                window.location.replace(`#/login${system.isSystem ? '' : '?sid=' + JSON.parse(window.localStorage.getItem('sid'))}`);
            },
            cancel() {
                this.isReadyOut = false;
            },
            gotoSignout() {
                this.isReadyOut = true;
            }
        }
    };
</script>