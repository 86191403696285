<template>
    <div class="modal-container" @click="handleMaskClick">
        <div :class="setClass()">
            <slot name="header"></slot>
            <slot></slot>
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<style scoped>
    .modal-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        background-color: rgba(0, 0, 0, .68);
        z-index: 9;
        font-size: 16px;
    }

    .overlay-body {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 268px;
        height: auto;
        padding: 76px 27px 30px 27px;
        border-radius: 12px;
        background-color: #fff;
        z-index: 9;
    }

    .overlay-body-opacity {
        position: absolute;
        left: 50%;
        top: 80%;
        transform: translate(-50%, -50%);
        padding: 0.76rem 0.36rem 0.3rem 0.36rem;
        border-radius: 0.12rem;
        z-index: 9;
    }
    .overlay-body-box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 268px;
        height: auto;
        border-radius: 12px;
        background-color: #fff;
        z-index: 9;
    }
</style>

<script>
    import Vue from 'vue';

    export default {
        name: 'v-modal',
        props: {
            disableMaskClick: { type: Boolean, default: false},
            clickClass: { type: String, default: ''},
            type: { type: String, default: ''},
        },
        data() {
            return {}
        },
        created() {
        },
        mounted() {

        },
        computed: {
        },
        methods: {
            setClass() {
                return `btn ${!this.type || this.type === 'primary' ? 'overlay-body' : ''} 
                ${this.type === 'opacity' ? 'overlay-body-opacity' : ''} 
                ${this.type === 'box' ? 'overlay-body-box' : ''}`;
            },
            handleMaskClick(e) {
               if (this.disableMaskClick && e.target.getAttribute('class').indexOf(this.clickClass) === -1) {
                    return;
                }
                this.$emit('maskClick', e.target);
            }
        }
    };
</script>